import React from 'react';
import { Metafields, Product, ProductTypes } from '../../../types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import { getMetafieldJSON, getMetafieldV2 } from '../../../utils/utils';
import ProductTypeVideo from './ProductTypeVideo';
import * as S from './ProductDescription.styles';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

const ProductAttributionDescription: React.FC<{
  shopMetafields: Metafields;
  product: Product;
}> = ({ product }) => {
  const { type } = useSelector((state: RootState) => state.product);
  const isGallery = type === ProductTypes.GALLERY;
  const sectionDisplay = getMetafieldV2('attribution_description_display', product?.metafields);
  const sectionTitle = getMetafieldV2('attribution_description_title', product?.metafields);
  const sectionTitleLogo = getMetafieldJSON('attribution_description_logo', product?.metafields);
  const sectionContent = getMetafieldV2('attribution_description_content', product?.metafields);
  const sectionImage = getMetafieldJSON('attribution_description_image', product?.metafields);
  const sectionVidMp4 = getMetafieldJSON('attribution_description_video_mp4', product?.metafields);
  const sectionVidWebm = getMetafieldJSON(
    'attribution_description_video_webm',
    product?.metafields
  );
  const sectionVidPoster = getMetafieldJSON(
    'attribution_description_video_poster',
    product?.metafields
  );

  if (!product || !sectionDisplay || (sectionDisplay && sectionDisplay === 'hide')) {
    return null;
  }

  return (
    <S.GalleryItemWrapper style={{ backgroundColor: !isGallery ? 'rgb(241,240,236)' : '#e3e0d8' }}>
      <div className="tmplt-product">
        <S.ProductAttributionDescription style={{ alignItems: 'flex-start' }}>
          {sectionDisplay === 'image' && (
            <div>
              <img src={sectionImage ? sectionImage[0]?.src : null} alt="" />
            </div>
          )}
          {sectionDisplay === 'video' && (
            <div>
              <ProductTypeVideo
                videoMp4={sectionVidMp4}
                videoWebm={sectionVidWebm}
                videoPoster={sectionVidPoster}
              />
            </div>
          )}
          <div>
            <div>
              <p className="typo__header typo__header--large typo__header--bold">
                {sectionTitleLogo ? (
                  <img src={sectionTitleLogo[0].src} alt={sectionTitleLogo.alt || sectionTitle} />
                ) : (
                  sectionTitle || null
                )}
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: sectionContent ? sectionContent : null,
                }}
              />
            </div>
          </div>
        </S.ProductAttributionDescription>
      </div>
    </S.GalleryItemWrapper>
  );
};

export default withQuery(ProductAttributionDescription);
