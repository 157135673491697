import React from 'react';
import { useSelector } from 'react-redux';
import { Metafields, Product } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import { withQueryOnlyData } from '../data/withQueryOnlyData';
import getProductDescription from '@/components/product/shared/getProductDescription';

type PropTypes = {
  product: Product;
  shopMetafields?: Metafields;
  showSize?: boolean;
  className?: string;
};

const ProductHeader = ({ product, shopMetafields, showSize, className }: PropTypes) => {
  const { productTitle: productTitleFromRedux } = useSelector((state: RootState) => state.product);
  const [, productSize] = getProductDescription(product, shopMetafields);
  const size = productSize && showSize ? productSize.split(' ')[0] : '';

  return (
    <header
      className={`tmplt-product__header ${className}`}
      style={{ justifyContent: 'space-between' }}
    >
      <h1
        data-testid="product-title"
        className="tmplt-product__title typo__header typo__header--large typo__header--bold"
      >
        {size} {productTitleFromRedux || product?.title}
      </h1>
    </header>
  );
};

ProductHeader.displayName = 'ProductHeader';
export default React.memo(withQueryOnlyData(ProductHeader));
