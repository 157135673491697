import { ModelViewerElement } from '@google/model-viewer';

const withDefaultModelPosition = async <T = void>(
  model: ModelViewerElement,
  callback: () => Promise<T>,
  orbit: string = ''
) => {
  const parent = model.parentNode;
  const body = document.querySelector('body');
  const originalStyle = model.getAttribute('style');
  // Move model viewer to the visible area of the screen since it only renders if the
  // in the viewport. The visibility is hidden but it still renders correctly once in the
  // visible area
  body?.appendChild(model);
  model.setAttribute('style', 'position: absolute; top: 0; left: 0;visibility: hidden;');
  model.pause();
  model.currentTime = 0;

  const cameraOrbit = model.cameraOrbit;
  const interpolation = model.interpolationDecay;

  model.interpolationDecay = 1;
  model.cameraOrbit = orbit;
  model.interactionPrompt = 'none';
  model.resetTurntableRotation();
  model.jumpCameraToGoal();

  await new Promise<void>((resolve) =>
    requestAnimationFrame(() => {
      requestAnimationFrame(() => resolve());
    })
  );

  await new Promise<void>((resolve) => {
    setTimeout(() => resolve(), 500);
  });

  const result = await callback();
  model.setAttribute('style', originalStyle ?? '');
  parent?.appendChild(model);
  model.play();
  model.interactionPrompt = 'auto';
  model.cameraOrbit = cameraOrbit;
  model.jumpCameraToGoal();
  model.interpolationDecay = interpolation;
  return result;
};

export default withDefaultModelPosition;
