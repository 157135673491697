import React from 'react';
import { Product, Metafields } from '@/types/ecommerce.types';
import styled from 'styled-components';
import { withQuery } from '../data/withQuery';
import { getMetafield, handleizeProductType } from '@/utils/utils';

const ImageIcon = styled.img`
  max-height: 35px;
  margin-bottom: 5px;
`;

const Icon = ({ svg }: { svg: string }) => <ImageIcon src={svg} alt="" />;

type PropTypes = {
  className?: string;
  product: Product;
  shopMetafields: Metafields;
};

const ValueProps = ({ product, shopMetafields, className = '' }: PropTypes) => {
  if (!shopMetafields || !product) {
    return null;
  }

  // Product type needs to be normalized to be accessed from metafields
  const productType = handleizeProductType(product?.productType);

  const featuresObj = JSON.parse(getMetafield('vp_copy', shopMetafields) ?? '{}');
  const featuresArr: string[] = featuresObj[productType];

  const featuresValuesObj = JSON.parse(getMetafield('vp_svg_url', shopMetafields) ?? '{}');
  const featuresValuesArr = featuresValuesObj[productType];

  if (!featuresArr) {
    return null;
  }

  return (
    <section className={`tmplt-product__icons-wrapper ${className}`} data-testid="features">
      {featuresArr?.map((title, i) => {
        const icon: string = featuresValuesArr[i];
        return (
          <figure className="tmplt-product__icon-item" key={title}>
            <Icon svg={icon} />
            <figcaption className="tmplt-product__icon-desc typo__paragraph--small">
              {title}
            </figcaption>
          </figure>
        );
      })}
    </section>
  );
};
ValueProps.displayName = 'ValueProps';
/* eslint-disable-next-line */
export default withQuery(ValueProps);
