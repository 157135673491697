import React, { useRef, useEffect } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

type PropsType = {
  videoSrc?: string;
};

const options = {
  threshold: Array.from({ length: 101 }, (_, i) => i * 0.01),
  rootMargin: '0px 0px -25% 0px',
};

const VideoAnimatedComponent = ({ videoSrc }: PropsType) => {
  const ref = useRef<HTMLVideoElement>(null);
  const entry = useIntersectionObserver(ref, options);

  useEffect(() => {
    if (entry && ref?.current) {
      entry.intersectionRatio >= 0.4 ? ref.current.play() : ref.current.pause();
    }
  }, [entry?.intersectionRatio, ref]);

  if (!videoSrc) {
    return null;
  }

  return (
    <video
      ref={ref}
      style={{ width: '100%', height: '100%', borderRadius: '10px' }}
      loop
      autoPlay
      muted
      playsInline
      controls={false}
      src={videoSrc}
    >
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoAnimatedComponent;
