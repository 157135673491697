import { gql } from '@apollo/client';

const PRODUCT_METAFIELDS = `
  metafields(
    identifiers: [
      { namespace: "accentuate", key: "group_product_id" },
      { namespace: "accentuate", key: "group_product_name" },
      { namespace: "accentuate", key: "color" },
      { namespace: "accentuate", key: "color_name" },
      { namespace: "accentuate", key: "product_color_collection" },
      { namespace: "accentuate", key: "title" },
      { namespace: "accentuate", key: "print_size" },
      { namespace: "accentuate", key: "use_type" },
      { namespace: "framebuilder", key: "bleed_percent" },
      { namespace: "framebuilder", key: "canBeRotated" },
      { namespace: "framebuilder", key: "hallway_size_label" },
      { namespace: "framebuilder", key: "variant_framebuilder_image" },
      { namespace: "framebuilder", key: "framebuilder_inner_width_px" },
      { namespace: "framebuilder", key: "framebuilder_inner_length_px" },
      { namespace: "framebuilder", key: "printed_width_inches" },
      { namespace: "framebuilder", key: "printed_length_inches" },
      { namespace: "framebuilder", key: "minimum_dpi" },
      { namespace: "framebuilder", key: "mat_type" },
      { namespace: "framebuilder", key: "mat_group" },
      { namespace: "framebuilder", key: "image_required_for_purchase" },
      { namespace: "framebuilder", key: "3d_builder_enabled" },
      { namespace: "framebuilder", key: "product_pdp_type" },
      { namespace: "framebuilder", key: "list_page_variation" },
      { namespace: "framebuilder", key: "pdp_product_style_title" },
      { namespace: "framebuilder", key: "pdp_product_discount_text" },
      { namespace: "framebuilder", key: "3d_model_position"},
      { namespace: "framebuilder", key: "3d_model_mobile_position"},
      { namespace: "framebuilder", key: "mat_shift_y" },
      { namespace: "artistic_prints", key: "available_products" },
      { namespace: "artistic_prints", key: "ap_print_asset_url" },
      { namespace: "substrate_picker", key: "substrate_title_alias" },
      { namespace: "substrate_picker", key: "substrate_product_teaser" },
      { namespace: "gallery", key: "gallery_item_count" },
      { namespace: "gallery", key: "tile" },
      { namespace: "gallery", key: "tile_options" },
      { namespace: "gallery", key: "gallery_background_size_config" },
      { namespace: "gallery", key: "gallery_background_image" },
      { namespace: "gallery", key: "frame_orientation" },
      { namespace: "gallery", key: "gallery_width_in" },
      { namespace: "gallery", key: "gallery_height_in" },
      { namespace: "gallery", key: "gallery_measure_position" },
      { namespace: "product", key: "plp_use_type" },
      { namespace: "media", key: "editorial_photos" },
      { namespace: "videos", key: "product_details_video_url" },
      { namespace: "videos", key: "product_details_video_poster" },
      { namespace: "videos", key: "product_details_video_desktop_url" },
      { namespace: "videos", key: "product_details_video_desktop_poster" },
      { namespace: "videos", key: "product_details_video_mobile_url" },
      { namespace: "videos", key: "product_details_video_mobile_poster" },
      { namespace: "ymal", key: "ymal_collection" },
      { namespace: "ymal", key: "pdp_ymal_title" }
      { namespace: "interactive", key: "pdp_interactive_blocks" }
      { namespace: "accentuate", key: "attribution_logo" }
      { namespace: "accentuate", key: "attribution_text" }
      { namespace: "accentuate", key: "attribution_prefix_label" }
      { namespace: "accentuate", key: "attribution_description_title" }
      { namespace: "accentuate", key: "attribution_description_logo" }
      { namespace: "accentuate", key: "attribution_description_display" }
      { namespace: "accentuate", key: "attribution_description_image" }
      { namespace: "accentuate", key: "attribution_description_video_mp4" }
      { namespace: "accentuate", key: "attribution_description_content" }
      { namespace: "gallery", key: "gallery_orientation" }
      { namespace: "accentuate", key: "product_description_image_alternative" }
    ]
  ) {
    key
    namespace
    value
    id
  }
`;

export const PRODUCT_NODE = gql`
  fragment ProductNode on Product {
    id
    title
    handle
    description
    tags
    productType
    vendor
    featuredImage {
      id
      url
      altText
      width
      height
    }
    ${PRODUCT_METAFIELDS}
    priceRange {
      maxVariantPrice {
        amount
      }
      minVariantPrice {
        amount
      }
    }
    images(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          src
          id
        }
      }
    }
    variants(first: 10) {
      edges {
        node {
          title
          availableForSale
          currentlyNotInStock
          quantityAvailable
          price {
            amount
            currencyCode
          }
          compareAtPrice {
            amount
            currencyCode
          }
          selectedOptions {
            name
            value
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_BY_HANDLE = gql`
  query productByHandle($handle: String!) {
  productByHandle(handle: $handle) {
    id
    handle
    title
    description
    descriptionHtml
    tags
    productType
    onlineStoreUrl
    vendor
    seo{
      title
    }
    mat_variants: metafield(namespace: "framebuilder", key: "mat_group") {
      references(first: 10) {
        edges {
          node {
            ... on Product {
              id
              handle
              mat_type: metafield(namespace: "framebuilder", key: "mat_type") {
                id
                value
              }
            }
          }
        }
      }
    }
    orientation_variants: metafield(namespace: "gallery", key: "gallery_orientation_group") {
      references(first: 10) {
        edges {
          node {
            ... on Product {
              id
              handle
              orientation_type: metafield(namespace: "gallery", key: "gallery_orientation") {
                id
                value
              }
            }
          }
        }
      }
    }
    hallway_size_product: metafield(
      namespace: "framebuilder"
      key: "hallway_size_product"
    ) {
      reference {
        ... on Product {
          id
          handle
          title
          group_title: metafield(
            namespace: "accentuate"
            key: "group_product_name"
          ) {
            value
          }
        }
      }
    }
    slider_images: metafield(namespace: "pdp", key: "pdp__product_slider_images") {
      references(first: 15) {
        edges {
          node {
            ... on MediaImage {
              id
              image {
                url
              }
            }
          }
        }
      }
    }
    variants(first: 40) {
      edges {
        node {
          id
          title
          sku
          selectedOptions {
            name
            value
          }
          price {
            amount
            currencyCode
          }
          compareAtPrice {
            amount
            currencyCode
          }
          image {
            originalSrc
          }
          availableForSale
          model_file: metafield(namespace: "framebuilder", key: "3d_model") {
            reference {
              ... on GenericFile {
                id
                url
              }

              ... on Model3d {
                sources {
                  format
                  url
                }
              }
            }
          }
          ${PRODUCT_METAFIELDS}
        }
      }
    }
    priceRange {
      maxVariantPrice {
        amount
      }
      minVariantPrice {
        amount
      }
    }
    options {
      id
      name
      values
    }
    images(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          src
          id
        }
      }
    }
    media(first: 210) {
      edges {
        node {
          mediaContentType
          previewImage {
            originalSrc
          }
          ... on Video {
            id
            sources {
              url
              format
              height
              width
            }
          }
        }
      }
    }
    ${PRODUCT_METAFIELDS}
  }
}
`;

export const GET_COLLECTION_BY_HANDLE = gql`
  query collectionByHandle($handle: String!) {
    collectionByHandle(handle: $handle) {
      id
      title
      products(first: 10) {
        edges {
          node {
            ...ProductNode
          }
        }
      }
    }
  }
  ${PRODUCT_NODE}
`;

export const GET_COLLECTIONS = gql`
  query collections($query: String) {
    collections(query: $query, first: 5) {
      edges {
        node {
          id
          title
          products(first: 10) {
            edges {
              node {
                ...ProductNode
              }
            }
          }
        }
      }
    }
  }
  ${PRODUCT_NODE}
`;

export const GET_PRODUCT_RECOMMENDATIONS = gql`
  query productRecommendations($productId: ID!) {
    productRecommendations(productId: $productId) {
      title
      id
      handle
      tags
      productType
      vendor
      featuredImage {
        id
        url
        altText
        width
        height
      }
      priceRange {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      ${PRODUCT_METAFIELDS}
      images(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            src
            id
          }
        }
      }
      variants(first: 10) {
        edges {
          node {
            id
            title
            sku
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
            selectedOptions {
              name
              value
            }
            image {
              originalSrc
            }
            availableForSale
            ${PRODUCT_METAFIELDS}
          }
        }
      }
    }
  }
`;

export const GET_SHOP_METAFIELDS = gql`
  query {
    shop {
      name
      metafields(
        identifiers: [
          { namespace: "framebuilder", key: "max_upload_size_mb" }
          { namespace: "framebuilder", key: "view_in_room_parameters_v_1_2" }
          { namespace: "framebuilder", key: "max_upload_size_mb" }
          { namespace: "framebuilder", key: "mat_toggle_order" }
          { namespace: "globals", key: "faq_question" }
          { namespace: "globals", key: "faq_answer" }
          { namespace: "globals", key: "faq_pdp_opt_in" }
          { namespace: "globals", key: "low_res_warning_icon" }
          { namespace: "globals", key: "low_res_warning_label" }
          { namespace: "globals", key: "product_listings" }
          { namespace: "globals", key: "quick_ship_badge_asset" }
          { namespace: "globals", key: "quick_ship_badge_display" }
          { namespace: "globals", key: "quick_ship_label" }
          { namespace: "types", key: "pdp_product_type_alias" }
          { namespace: "types", key: "pt_description_image" }
          { namespace: "types", key: "pt_description_title" }
          { namespace: "types", key: "pt_description_content" }
          { namespace: "types", key: "pt_description_media_display" }
          { namespace: "types", key: "pt_description_video_mp4" }
          { namespace: "types", key: "pt_description_video_webm" }
          { namespace: "types", key: "pt_description_video_poster" }
          { namespace: "types", key: "vp_copy" }
          { namespace: "types", key: "vp_svg_url" }
        ]
      ) {
        key
        namespace
        value
        id
      }
    }
  }
`;
